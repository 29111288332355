import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect, useState } from 'react';
import { loginSuccess, logout } from 'store/actions'; 
import { useNavigate } from 'react-router-dom';
 
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const navigate = useNavigate();

// // Check login condition
//     useEffect(() => {
//         if (localStorage.getItem('authToken')) {
//             dispatch(loginSuccess(true));
//             console.log("test")
//         } else {
//             dispatch(logout(false));
//             navigate('/pages/login/login3');
//             console.log("test false")
//         }
//     }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
