import axios from 'axios';

const basAxiosInstance = axios.create({
    //baseURL: 'http://p9:5555/api/',
     baseURL: 'https://waterapp.littera.in/WaterAPI/api/',
});

basAxiosInstance.interceptors.request.use(
    (config) => {
        try {
            if (config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            } else {
                config.headers['Content-Type'] = 'application/json';
            }
        } catch (error) {
            console.log('interceptors error');
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
basAxiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default basAxiosInstance;
