import { RIGHTS } from './actions';

const initialState = {
    rights: []
};

const rightsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RIGHTS:
            return {
                ...state,
                rights: action.payload
            };

        default:
            return state;
    }
};

export default rightsReducer;
