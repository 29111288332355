import dashboard from './dashboard';
import pages from './pages';
import transaction, { master, report } from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
     items: [ master, transaction, report]
};

export default menuItems;
