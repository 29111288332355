// assets
import { IconTypography, IconPalette, IconShadow, IconKey, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconKey,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //


const transaction = {
    id: 'transaction',
    title: 'Transaction',
    type: 'group',
    children: [

        {
            id: 'Crop',
            title: 'Crops',
            type: 'item',
            url: '/utils/util-CropsList',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
            {
                id: 'Animal',
                title: 'Animal',
                type: 'item',
                url: '/utils/util-AnimalList',
                icon: icons.IconShadow,
                breadcrumbs: false
            },
        
        {
            id: 'Villageinfo',
            title: 'Villages Info',
            type: 'item',
            url: '/utils/util-VillagesInfoesList',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        {
            id: 'WaterBodies',
            title: 'Water Bodies',
            type: 'item',
            url: '/utils/util-WaterBodiesList',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        {
            id: 'WaterTask',
            title: 'Water Task',
            type: 'item',
            url: '/utils/util-WaterTaskList',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
      
    ]
};

export default transaction;


export const master = {
    id: 'master',
    title: 'Master',
    type: 'group',
    children: [
        {
            id: 'state',
            title: 'State',
            type: 'item',
            url: '/utils/util-StatesList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'District',
            title: 'District',
            type: 'item',
            url: '/utils/util-districtList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'Block',
            title: 'Block',
            type: 'item',
            url: '/utils/util-blocksList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'GramPanchayat',
            title: 'Gram Panchayat',
            type: 'item',
            url: '/utils/util-GramPanchayatsList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'Village',
            title: 'Village',
            type: 'item',
            url: '/utils/util-VillagesList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'PanchayatDesignation',
            title: 'Panchayat Designation',
            type: 'item',
            url: '/utils/util-PanchayatDesignationList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'Roles',
            title: 'Roles',
            type: 'item',
            url: '/utils/util-RolesList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'RolePermission',
            title: 'Role Permission',
            type: 'item',
            url: '/utils/util-RolePermissionList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'User',
            title: 'Users',
            type: 'item',
            url: '/utils/util-UsersList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'CropType',
            title: 'Crop Type',
            type: 'item',
            url: '/utils/util-CropTypesList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'AnimalType',
            title: 'Animal Type',
            type: 'item',
            url: '/utils/util-AnimalTypesList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'SoilType',
            title: 'Soil Type',
            type: 'item',
            url: '/utils/util-SoilTypesList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'WaterBodyType',
            title: 'Water Body Type',
            type: 'item',
            url: '/utils/util-WaterBodyTypesList',
            icon: icons.IconKey,
            breadcrumbs: false
        },
    ]
};

export const report = {
    id: 'report',
    title: 'Reports',
    type: 'group',
    children: [
        {
            id: 'summaryReport',
            title: 'Summary report',
            type: 'item',
             url: '/utils/village/util-SummaryReport',
            icon: icons.IconWindmill,
            breadcrumbs: false
        },
        {
            id: 'state',
            title: 'Village Summary report',
            type: 'item',
            url: '/utils/util-SummaryReport',
           
            icon: icons.IconWindmill,
            breadcrumbs: false
        },

    ]
};