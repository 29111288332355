import { ADD_STATE, UPDATE_STATE } from './actions';

const initialState = {
    stateList: []
};

const stateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_STATE:
            return {
                ...state,
                stateList: action.payload
            };

            case UPDATE_STATE:
                return {
                    ...state,
                    stateList: state.stateList.map((state) => {
                        return state.stateId === action.payload.Id ? { ...state, ...action.payload.updateData } : state;
                    }),
                };
        default:
            return state;
    }
};

export default stateReducer;
