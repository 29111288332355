import {
    Button,
    CardMedia,
    Collapse,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import basAxiosInstance from 'utils/baseApi';
import { useEffect, useState, useCallback } from 'react';
import { Box } from '@mui/system';
import RightsComponent from '../Component/RightsComponent';
import { exportToExcel } from '../Component/ProfileData';
import NewFilter from '../Component/NewFilter'
import axios from 'axios';
import { FaFilePdf } from 'react-icons/fa';
import { doc } from 'prettier';

// ==============================|| Animal List ||============================== //
export const Imageurl = 'https://waterapp.littera.in/WaterAPI/'

const VillageSummaryRepo = () => {
    const [data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState({}); // crops Type
    const [loading, setLoading] = useState(true);
    const [animalType, setAnimalType] = useState(null);
    const [totalPage, setTotalPage] = useState(0);
    const [villageData, setVillageData] = useState([]); //Village data
    const [selectedVillageData, setSelectedVillageData] = useState('');
    const [dataFetched, setDataFetched] = useState(false);
    const [waterTaskFetched, setWaterTaskFetched] = useState(false);
    const [rights, setrights] = useState({
        add: 0,
        edit: 0,
        delete: 0,
        view: 0,
        approve: 0
    });
    const [gramPanchayatData, setGramPanchayatData] = useState([]); // Gram panchayat data
    const [selectedGramPanchayat, setSelectedGramPanchayat] = useState('');
    const [blocksData, setBlocksData] = useState([]); // block data
    const [selectedBlock, setSelectedBlock] = useState('');
    const [stateData, setStateData] = useState([]); // State data
    const [selectedState, setSelectedState] = useState('');
    const [villagesInfoesWaterTask, setVillagesInfoesWaterTask] = useState([]);
    const [district, setdistrict] = useState([]); // District data
    const [selectedDistrictState, setSelectedDistrictState] = useState('');
    const [selectedBlockName, setSelectedBlockName] = useState('');
    const [villagesInfoes, setVillagesInfoes] = useState();
    const [panchayatDesignations, setPanchayatDesignations] = useState();
    const [taskFundedByNames, setTaskFundedByNames] = useState([]); //Task Funded By Names
    const [SoilTypeData, setSoilTypeData] = useState();
    const [vilData, setVilData] = useState()
    const [userName, setUserName] = useState();
    const [userRole, setUserRole] = useState('');
    const formatedDate = (allDate) => {
        const date = new Date(allDate);

        // Date components
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        // Time components
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    // Example usage
    console.log(formatedDate(new Date())); // Outputs: DD/MM/YYYY HH:MM:SS (current date and time)

    const formatNumber = (value) => {
        const number = parseFloat(value);
        return isNaN(number) ? 0 : number.toFixed(2);
    };
    const getUserName = RightsComponent();
    const fetchUserProfiles = async (userRole) => {
        try {
            const response = await basAxiosInstance.get(`UserProfiles/${userRole}`);
            if (response.status === 200) {
                setUserName(response.data);
                console.log("response", response);
                await fetchDataById(response.data.designationId); // Fetch designation data
                setLoading(true);
            }
        } catch (err) {
            console.error('Error fetching user profiles:', err);
        }
    };

    // Define the function to fetch data by ID
    const fetchDataById = async (id) => {
        try {
            const response = await basAxiosInstance.get(`PanchayatDesignations/${id}`);
            if (response.status === 200 || response.status === 201) {
                setPanchayatDesignations(response.data);
                console.log(response.data);
            }
        } catch (err) {
            console.error('Error fetching panchayat designations:', err);
        }
    };

    // Define the callback to fetch task funded by names
    const GetTaskFundedByNames = useCallback(async (userRole) => {
        try {
            const response = await basAxiosInstance.get(`WaterTask/GetTaskFundedByNames`);
            if (response.status === 200) {
                setTaskFundedByNames(response.data);
                console.log("ffffff", response.data);
                // Optionally fetch user profiles if needed
                fetchUserProfiles(userRole);
            }
        } catch (err) {
            console.log('error', err);
        }
    }, []);

    // useEffect to initialize data on component mount
    useEffect(() => {
        const initializeData = async () => {
            const UserRole = getUserName && getUserName.UserId;
            if (UserRole) {
                setUserRole(UserRole);
                // Fetch task funded by names first
                await GetTaskFundedByNames(UserRole);
            }
        };

        initializeData();
    }, [getUserName, GetTaskFundedByNames]);
    // Get village data through gram PanchayatId from server
    const fetchVillagesData = useCallback(async () => {
        try {
            const response = await basAxiosInstance.get(`Villages/GetVillageByPanchayatId/${selectedGramPanchayat}`);
            if (response.status === 200) {
                setVillageData(response.data);
            }
        } catch (err) {
            console.log('error', err);
        }
    }, [selectedGramPanchayat]);

    useEffect(() => {
        if (selectedGramPanchayat) {
            fetchVillagesData();
        }
    }, [selectedGramPanchayat, fetchVillagesData]);

    const fetchGramPanchayatData = useCallback(async () => {
        try {
            const response = await basAxiosInstance.get(`GramPanchayats/GetGramPanchayatByBlockId/${selectedBlock}`);
            if (response.status === 200) {
                setGramPanchayatData(response.data);
            }
        } catch (err) {
            console.log('error', err);
        }
    }, [selectedBlock]);

    const fetchBlockData = useCallback(async () => {
        try {
            const response = await basAxiosInstance.get(`Blocks/GetBlockByDistrictId/${selectedDistrictState}`);
            if (response.status === 200) {
                setBlocksData(response.data);
                await fetchGramPanchayatData();
                const foundBlock = response.data.find(block => block.id === selectedBlock);
                if (foundBlock) {
                    setSelectedBlockName(foundBlock.name);
                     // Fetch Gram Panchayat data after fetching blocks
                }
            }
        } catch (err) {
            console.log('error', err);
        }
    }, [selectedDistrictState, selectedBlock, fetchGramPanchayatData]);

    useEffect(() => {
        if (selectedDistrictState) {
            fetchBlockData();
        }
    }, [selectedDistrictState, fetchBlockData]);

    // Get district data from server
    const fetchDistrictData = useCallback(async () => {
        try {
            const response = await basAxiosInstance.get(`Districts/GetDistrictByStateId/${selectedState}`);
            if (response.status === 200) {
                setdistrict(response.data);
            }
        } catch (err) {
            console.log('error', err);
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedState) {
            fetchDistrictData();
        }
    }, [selectedState]);

    // Get State data from server
    const fetchStateData = async () => {
        try {
            const response = await basAxiosInstance.get('States');
            if (response.status === 200) {
                setStateData(response.data);
            }
        } catch (err) {
            console.log('error', err);
        }
    };

    useEffect(() => {
        fetchStateData();
    }, []);

    const handleChangeSelect = (event) => {
        setSelectedState(event.target.value);
    };
    const handleSelectDistrict = (event) => {
        setSelectedDistrictState(event.target.value);
    };
    const handleSelectBlock = (event) => {
        const newBlock = event.target.value;

        // Reset dependent fields
        setSelectedBlock(newBlock);
        setSelectedGramPanchayat(''); // Clear Gram Panchayat
        setSelectedVillageData('');  // Clear Village
        setVillageData([]);          // Clear Village Data
        setGramPanchayatData([]);    // Clear Gram Panchayat Data

        // Optionally, fetch block data or other data if needed
        fetchBlockData();           // Refetch block data if needed
    };

    const handleSelectGramPanchayat = (event) => {
        setSelectedGramPanchayat(event.target.value);
    };
    const handleSelectVillage = (event) => {
        setSelectedVillageData(event.target.value);
    };

    // Fetch Animal Type Data and send it to parent
    // Fetch Animal Type Data and send it to parent
    const fetchAnimalTypeData = async (page = 1, pageSize = 10) => {
        if (!selectedBlock) {
            alert("Please select a block before proceeding.");
            return;
        }

        try {
            let apiUrl = `Villages/Get_Village_Summery_Report?pageSize=${pageSize}&offset=${page}`;
            // let apiUrl = `Villages/Get_Village_Summery_Report?pageSize=0&offset=0`;
            if (selectedVillageData) {
                apiUrl += `&villageId=${selectedVillageData}`;
            } else if (selectedGramPanchayat) {
                apiUrl += `&gramPanchayatId=${selectedGramPanchayat}`;
            } else if (selectedBlock) {
                apiUrl += `&blockId=${selectedBlock}`;
            }

            const response = await basAxiosInstance.get(apiUrl);
            if (response.status === 200) {
                setAnimalType(response.data.item1);
                setTotalPage(Math.ceil(response.data.item2 / 10)); // Assuming item2 is the total count

            }
        } catch (err) {
            console.log('error', err);
        }
    };

    // Effect to fetch data based on selectedBlock and page
    useEffect(() => {
        if (selectedBlock) {
            // fetchAnimalTypeData(page + 1); // Fetch data for the current page
        }
    }, [selectedBlock, page]);

    // Pagination handler
    const handlePageChange = (event, value) => {
        setPage(value - 1); // Set page index (0-based)
        fetchAnimalTypeData(value); // Fetch data for the selected page
    };



    const rightsCo = RightsComponent();
    useEffect(() => {
        const Animal = rightsCo && rightsCo.Animal;
        setrights({
            add: Animal & 2,
            edit: Animal & 4,
            delete: Animal & 8,
            view: Animal & 1,
            approve: Animal & 16
        });
    }, [rightsCo]);

    const handleRowClick = (id) => {
        setOpen((previewOpenRows) => ({
            ...previewOpenRows,
            [id]: !previewOpenRows[id]
        }));
    };
    console.log("selectedVillageData", selectedVillageData);
    // Get data from server

    useEffect(() => {
        if (animalType) {
            console.log("blockdata", animalType);
        }
    }, [animalType]);




    const fetchAllAnimalTypeData = async () => {
        if (!selectedBlock) {
            alert("Please select a block before proceeding.");
            return;
        }

        try {
            let apiUrl = `Villages/Get_Village_Summery_Report?pageSize=0&offset=0`; // Large page size to get all data
            if (selectedVillageData) {
                apiUrl += `&villageId=${selectedVillageData}`;
            } else if (selectedGramPanchayat) {
                apiUrl += `&gramPanchayatId=${selectedGramPanchayat}`;
            } else if (selectedBlock) {
                apiUrl += `&blockId=${selectedBlock}`;
            }

            const response = await basAxiosInstance.get(apiUrl);
            if (response.status === 200) {
                console.log("excel response", response.data.item1)
                return response.data.item1; // Return all data
            }
        } catch (err) {
            console.log('error', err);
        }
    };
    const fetchData = async (villageId) => {
        if (!villageId) return; // Guard clause to prevent empty requests
        console.log('fetching data', villageId);
        try {
            const response = await basAxiosInstance.get(`Villages/Get_Village_Summery_Report?villageId=${villageId}`);
            if (response.status === 200) {
                setData(response.data.item1[0]);
                console.log("response", response.data.item1[0]);
                setDataFetched(true);
                return true; // Indicate success
            }
        } catch (err) {
            console.error('Error fetching  summary report:', err);
            return false; // Indicate failure
        }
        return false; // Indicate failure
    };

    const fetchDataVillagesInfoesWaterTask = async (villageId) => {
        if (!villageId) return; // Guard clause to prevent empty requests
        try {
            const response = await basAxiosInstance.get(`WaterTask/GetWaterTaskbyVillageId/${villageId}`);
            if (response.status === 200) {
                setVillagesInfoesWaterTask(response.data);
                console.log('WaterTask', response.data);
                fetchDataWaterBodiesGroupedByWaterBodyType(selectedVillageData);
                setWaterTaskFetched(true);
            }
        } catch (err) {
            console.log('Error fetching water task:', err);
        }
    };

    const fetchDataVillagesInfoes = async (villageId) => {
        if (!villageId) return; // Guard clause to prevent empty requests
        try {
            const response = await basAxiosInstance.get(`VillagesInfoes/${villageId}`);
            if (response.status === 200) {
                setVillagesInfoes(response.data);
                // Return data if needed
                return response.data;
            }
        } catch (err) {
            console.log('error', err);
        }
    };

    const fetchSoilType = async (id) => {
        if (!id) return; // Ensure id is defined
        try {
            const response = await basAxiosInstance.get(`SoilTypes/${id}`);
            if (response.status === 200) {
                setSoilTypeData(response.data);
                console.log("setSoilTypeData", response.data);
                return response.data; // Return data if needed
            }
        } catch (err) {
            console.log('error', err);
        }
    };

    const handlePrint = () => {
        const content = document.getElementById('printData').innerHTML;
        const formattedContent = `<!DOCTYPE html> 
    <html>
        <head>
            <meta charset="utf-8" />
            <title>Document </title>
        </head>
        <body>
            ${content}
        </body>
    </html>`;
        const printWindow = window.open('', '_blank');
        printWindow.document.write(formattedContent);
        printWindow.document.close();
        printWindow.print();
    };

    useEffect(() => {
        if (selectedVillageData) {
            setDataFetched(false);
            setWaterTaskFetched(false);
            fetchData(selectedVillageData);
            fetchDataVillagesInfoesWaterTask(selectedVillageData);
            fetchSoilType(); // Ensure this has correct parameters if required
        }
    }, [selectedVillageData]);

    const handleDelete = async (row) => {
        let dataFetchedSuccessfully = false;
        let villagesInfoesData = null;
        let soilData = null;

        try {
            setDataFetched(false);
            setWaterTaskFetched(false);

            // Fetch data and handle errors, but continue regardless
            try {
                dataFetchedSuccessfully = await fetchData(row.villageId);
            } catch (err) {
                console.error('Error fetching  summary report:', err);
            }

            try {
                await fetchDataVillagesInfoesWaterTask(row.villageId);
            } catch (err) {
                console.error('Error fetching water task:', err);
            }

            try {
                villagesInfoesData = await fetchDataVillagesInfoes(row.villageId);
            } catch (err) {
                console.error('Error fetching villages info:', err);
            }

            if (villagesInfoesData && villagesInfoesData.soilTypeId) {
                try {
                    soilData = await fetchSoilType(villagesInfoesData.soilTypeId);
                } catch (err) {
                    console.error('Error fetching soil type:', err);
                }
            }

        } catch (err) {
            console.error('Error in handleDelete:', err);
        } finally {
            // Ensure that handlePrint is called regardless of the success or failure of API calls
            handlePrint();
            console.log('delete', row.villageId);
        }
    };






    const exportToExcelFile = async () => {
        try {
            const allData = await fetchAllAnimalTypeData();
            GetPrintData(allData)
            // Assuming exportToExcelFile is a function that handles Excel export
            const exportToExcel = (allData) => {
                GetPrintData(allData);
                const table = document.getElementById('printData1');

                const cloneTable = table.cloneNode(true);

                const image = cloneTable.getElementsByTagName('img');
                Array.from(image).forEach((img) => {
                    img.parentNode.removeChild(img);
                });

                const cells = cloneTable.getElementsByTagName('td');
                Array.from(cells).forEach((cell) => {
                    cell.style.border = '1px solid #919191';
                });
                const rows = cloneTable.getElementsByTagName('tr');
                Array.from(rows).forEach((cell) => {
                    cell.style.border = '1px solid #919191';
                });
                const colmns = cloneTable.getElementsByTagName('th');
                Array.from(colmns).forEach((cell) => {
                    cell.style.border = '1px solid #919191';
                });

                const html = cloneTable.innerHTML;

                const blob = new Blob([html], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'table_data.xls');

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            };
        } catch (err) {
            console.log('error', err);
        }
    };

    function GetPrintData(alldata) {

        var str = '<table border="1">\
       <tr><td colspan="18" style="text-align:center"> Summary Report</td></tr>\
       <tr><td colspan="18" style="text-align:center">>Block-'+ alldata[0].blockname + '</td></tr>\
       <tr><td rowspan="2">Sl no.</td><td rowspan="2">Name of State</td><td rowspan="2">Name of District</td><td rowspan="2">Name of Block</td><td rowspan="2">Name of panchayat</td><td rowspan="2">Name of village</td><td rowspan="2">Total HHs in Village</td><td rowspan="2">Total Population In village</td><td rowspan="2">Total GeoGraphical Area in village(In Acre)</td><td rowspan="2">Average Annual Rainfall(In mm)</td><td colspan="3"style="text-align:center">Total Available water in village</td><td colspan="4" style="text-align:center">Total Water Requirement of village (in Lakh Litres)</td><td rowspan="2">Status of water Surplus/Deficit(in Lakh Litres)</td></tr>\
       <tr><td>From Rainfall(Ib Lakh Litres)</td><td>From Water Bodies(Ib Lakh Litres)</td><td>Total (In Lakh Litres)</td><td>For Corps(In Litres)</td><td>For Animals(In Litres)</td><td>For Human(In Litres)</td><td>Total(In Litres)</td></tr>'


        alldata.map((item, index) => {
            str += '<tr>' +
                '<td>' + (index + 1) + '</td>' +
                '<td>' + (item.statename) + '</td>' +
                '<td>' + (item.districtname) + '</td>' +
                '<td>' + (item.blockname) + '</td>' +
                '<td>' + (item.grampanchayatname) + '</td>' +
                '<td>' + (item.villagename) + '</td>' +
                '<td>' + (item.total_hhs || '0') + '</td>' +
                '<td>' + (item.total_population_in_village || '0') + '</td>' +
                '<td>' + (item.total_geographical_area_in_village || '0') + '</td>' +
                '<td>' + (item.avg_annual_rainfall || '0') + '</td>' +
                '<td>' + (item.avl_water_from_rainfall || '0') + '</td>' +
                '<td>' + (item.avl_water_from_waterbodies || '0') + '</td>' +
                '<td>' + (item.total_available_water || '0') + '</td>' +
                '<td>' + (item.water_required_for_corp || '0') + '</td>' +
                '<td>' + (item.water_required_for_animal || '0') + '</td>' +
                '<td>' + (item.water_required_for_human || '0') + '</td>' +
                '<td>' + (item.total_water_required || '0') + '</td>' +
                '<td>' + (item.status_of_deficit_surplus || '0') + '</td>' +
                '</tr>';
        });


        str = str + "</table>"
        document.getElementById('dvExcelData').innerHTML = str;
        exportToExcel();

    }

    //=================DownLoad PDF=================//

    const currentDateAndTime = () => {
        const date = new Date();
        const isoString = date.toISOString();
        return isoString;

    };


    const PotentialIncreaseWaterCapicity = villagesInfoesWaterTask.length > 0 && villagesInfoesWaterTask[0].expectedWaterCapacity;




    //=================DownLoad PDF=================//



    // Retrieve the JSON string from sessionStorage
    // const GetTaskFundedByNames = useCallback(async (userRole) => {
    //     try {
    //         const response = await basAxiosInstance.get(`WaterTask/GetTaskFundedByNames`);
    //         if (response.status === 200) {
    //             setTaskFundedByNames(response.data);
    //             fetchUserProfiles(userRole);
    //         }
    //     } catch (err) {
    //         console.log('error', err);
    //     }
    // }, []);

    return (
        <>
            {rights.view === 0 ? (
                <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                    <Typography>"Access Denied : You are not authorized to perform this action"</Typography>
                </Box>
            ) : (
                <MainCard
                    title=" Summary Report"
                >
                    <SubCard>
                        <Grid container justifyContent="center" spacing={2.5} lineHeight={3} component="form">
                            <Grid item xs={12} sm={5.5} md={3}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="state-lable">State</InputLabel>
                                    <Select labelId="state-lable" id="select-Id" value={selectedState} label="State" onChange={handleChangeSelect}>
                                        {stateData.map((stateData, ind) => (
                                            <MenuItem key={ind} value={stateData.stateId}>
                                                {stateData.stateName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="state-lable">District</InputLabel>
                                    <Select
                                        labelId="state-lable"
                                        id="select-Id"
                                        value={selectedDistrictState}
                                        label="District"
                                        onChange={handleSelectDistrict}
                                    >
                                        {district.map((stateData, ind) => (
                                            <MenuItem key={ind} value={stateData.districtId}>
                                                {stateData.districtName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="state-lable">Block</InputLabel>
                                    <Select labelId="state-lable" id="select-Id" value={selectedBlock} label="Block" onChange={handleSelectBlock}>
                                        {blocksData.map((stateData, ind) => (
                                            <MenuItem key={ind} value={stateData.blockId}>
                                                {stateData.blockName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="state-lable">Gram Panchayat Name</InputLabel>
                                    <Select
                                        labelId="state-lable"
                                        id="select-Id"
                                        value={selectedGramPanchayat}
                                        label="Gram Panchayat Name"
                                        onChange={handleSelectGramPanchayat}
                                    >
                                        {gramPanchayatData.map((stateData, ind) => (
                                            <MenuItem key={ind} value={stateData.gramPanchayatId}>
                                                {stateData.gramPanchayatName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="state-lable">Village Name</InputLabel>
                                    <Select
                                        labelId="state-lable"
                                        id="select-Id"
                                        value={selectedVillageData}
                                        label="Village Name"
                                        onChange={handleSelectVillage}
                                    >
                                        {villageData.map((stateData, ind) => (
                                            <MenuItem key={ind} value={stateData.villageId}>
                                                {stateData.villageName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3}>
                                <Grid marginBottom="55px">
                                    <Button
                                        type="button"
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        sx={{ width: 100 }}
                                        onClick={() => {
                                            fetchData(selectedVillageData);
                                            fetchAnimalTypeData();; // Fetch animal type data on search using selectedBlock
                                        }}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SubCard>
                    {/* <NewFilter fetchData={fetchData} villageData={villageData} setVillageData={setVillageData} selectedVillageData={selectedVillageData} setSelectedVillageData={setSelectedVillageData} /> */}


                    <SubCard>
                        <Box sx={{ mb: 2 }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<FaFilePdf />}
                                onClick={exportToExcelFile}

                            >
                                Export to Excel
                            </Button>
                        </Box>
                        <div id="dvExcelData" style={{ display: "none" }}></div>
                        <TableContainer component={Paper} id='printData1'>
                            <Box sx={{ padding: 2, alignItems: "center" }}>
                                <Typography variant="h1" component="div" style={{ textAlign: "center", fontSize: "1rem" }}>
                                    Block Name: {
                                        blocksData.find(block => block.blockId === selectedBlock)?.blockName || 'None'
                                    }
                                </Typography>
                            </Box>
                            <Table style={{ borderTop: "1px solid #8080805c" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Sl.No</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Name of State</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Name of District</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Name of Block</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Name of Panchayat</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Name of Village</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Total HHs in Village</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Total Population in Village</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Total Geographical Area of Village (acre)</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c" }}>Average Annual Rainfall (mm)</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c", borderBottom: "1px solid #8080805c", textAlign: "center" }}>
                                            Total Available Water in Village (In Lakh Liters)
                                            <Table>
                                                <TableBody>
                                                    <TableRow style={{ borderBottom: "none" }}>
                                                        <TableCell style={{ borderBottom: "none" }}>From Rainfall</TableCell>
                                                        <TableCell style={{ borderBottom: "none" }}>From Water Bodies</TableCell>
                                                        <TableCell style={{ borderBottom: "none" }}>Total</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c" }}>
                                            Total Water Requirement of Village (in lakh liters)
                                            <Table>
                                                <TableBody>
                                                    <TableRow style={{ borderBottom: "none" }}>
                                                        <TableCell style={{ borderBottom: "none" }}>For Crops</TableCell>
                                                        <TableCell style={{ borderBottom: "none" }}>For Animals</TableCell>
                                                        <TableCell style={{ borderBottom: "none" }}>For Human</TableCell>
                                                        <TableCell style={{ borderBottom: "none" }}>Total</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableCell>

                                        <TableCell style={{ borderLeft: "1px solid #8080805c" }}>Status of Water Surplus/Deficit (In Lakh liters)</TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #8080805c" }}>Download Full Report of Village(In PDF/Excel)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {animalType?.map((row, index) => (
                                        <TableRow key={row.villageId}>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{index + 1}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{row.statename}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{row.districtname}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{row.blockname}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{row.grampanchayatname}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{row.villagename}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{row.total_hhs}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{row.total_population_in_village}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{row.total_geographical_area_in_village || 0}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>{formatNumber(row?.avg_annual_rainfall || 0)}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow style={{ borderBottom: "none" }}>

                                                            <TableCell style={{ borderBottom: "none", }}>{formatNumber(row.avl_water_from_rainfall || 0)}</TableCell>
                                                            <TableCell style={{ borderBottom: "none", }}>{formatNumber(row.avl_water_from_waterbodies || 0)}</TableCell>
                                                            <TableCell style={{ borderBottom: "none", }}>{formatNumber(row.total_available_water || 0)}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c", padding: "0px" }}>
                                                <Table>
                                                    <TableRow style={{ borderBottom: "none", padding: "0px" }}>
                                                        <TableCell style={{ borderBottom: "none", }}>
                                                            {isNaN(parseFloat(row.water_required_for_corp)) ? 0 : parseFloat(row.water_required_for_corp).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{ borderBottom: "none", }}>
                                                            {isNaN(parseFloat(row.water_required_for_animal)) ? 0 : parseFloat(row.water_required_for_animal).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{ borderBottom: "none", }}>
                                                            {isNaN(parseFloat(row.water_required_for_human)) ? 0 : parseFloat(row.water_required_for_human).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{ borderBottom: "none", }}>
                                                            {isNaN(parseFloat(row.total_water_required)) ? 0 : parseFloat(row.total_water_required).toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>

                                                </Table>
                                            </TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }}> {isNaN(parseFloat(row.status_of_deficit_surplus)) ? 0 : parseFloat(row.status_of_deficit_surplus).toFixed(2)}</TableCell>
                                            <TableCell style={{ borderLeft: "1px solid #8080805c" }} onClick={() => handleDelete(row)}> <FaFilePdf size={14} color="red" cursor="pointer" /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination count={totalPage} onChange={handlePageChange} />
                    </SubCard>



                </MainCard>
            )}
          <Grid id="printData" style={{ display: "none" }}>
                <Typography
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 800,
                        fontSize: '18px'
                    }}
                >
                    Jal Suraksha Report
                </Typography>

                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>Section A: Information of the Village</Typography>
                </Grid>
                <Grid container lineHeight={3} style={{ border: '1px solid gray' }}>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Name Of Village: {data?.villagename}
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Name of Gram Panchayat: {data?.grampanchayatname}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Name of Block: {data?.blockname}
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Name of District: {data?.districtname}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Name of State: {data?.statename}
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Date of Report Generation: {formatedDate(currentDateAndTime())}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>Section B: Village Details</Typography>
                </Grid>
                <Grid container lineHeight={3} style={{ border: '1px solid gray' }}>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Total households in village: {data?.total_hhs}
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Total Population in village: {data?.total_population_in_village}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Total Geographical Area of village: {data?.total_geographical_area_in_village} acre
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '50%', paddingLeft: 2 }}>
                            Average annual Rainfall: {(data?.avg_annual_rainfall)} mm
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%', paddingLeft: 2 }}>
                        <Grid item>Soil type in village: {SoilTypeData?.soilTypeName}</Grid>
                    </Grid>
                </Grid>

                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>Section C: Status of Water Availability and Usage in the Villages</Typography>
                </Grid>

                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>a: Total Available Water in the Village (Source Wise)</Typography>
                </Grid>
                <Grid container lineHeight={3} style={{ border: '1px solid gray' }}>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total available Water (in lakh ltr)
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.total_available_water)}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total available Water from Rainfall (in lakh ltr)
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.avl_water_from_rainfall)}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total available Water from Water bodies
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.avl_water_from_waterbodies)}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Type of Water bodies and availability of water
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}></Grid>
                    </Grid>
                </Grid>

                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>b: Total Water requirements in the Village (Usage Wise)</Typography>
                </Grid>
                <Grid container lineHeight={3} style={{ border: '1px solid gray' }}>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total water required for Crops (in lakh ltr)
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.water_required_for_corp)}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total water required for Animals (in lakh ltr)
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.water_required_for_animal)}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total water required for Human Needs (in lakh ltr)
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.water_required_for_human)}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total water required for Village (in lakh ltr)
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.total_water_required)}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>
                        * Consolidated calculation is provided based on the manual inputs entered in the application on crop
                        varieties and animal types
                    </Typography>
                </Grid>

                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>c: Status of Water Deficit/Surplus in the village</Typography>
                </Grid>
                <Grid item="true" lineHeight={2} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>
                        Insert the table and digram of Available vs Deficit with the details of water deficit/surplus in the
                        village in laks ltr
                    </Typography>
                </Grid>
                <Grid container lineHeight={3} style={{ border: '1px solid gray' }}>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total Available Water in Village (in lakh ltr)
                        </Grid>

                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.total_available_water)}
                        </Grid>
                    </Grid>

                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total Water Reqiurement of Village (in lakh ltr)
                        </Grid>

                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.total_water_required)}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ fontWeight: 700, border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total Deficit/Surplus (in lakh ltr)
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', fontWeight: 700, width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.status_of_deficit_surplus)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>Section D: Information on the Water Improvement Task </Typography>
                </Grid>
                <Grid item="true" lineHeight={2} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>a. Report on Proposed Water Improvement task in village</Typography>
                </Grid>

                <TableContainer>
                    <Table border={1} style={{ wordBreak: 'keep-all' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Name of activity
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Name of Village
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Name of Panchayat
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Location of activity
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Type of activity
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Nature of activity
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Activity funded by
                                </TableCell>
                                {/* <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                            Unit of activity
                                        </TableCell> */}
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Total unit of activity
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Tentative activity start year and month
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Estimate completion time of activity (month and day)
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Per unit Estimate cost of activity in Rs.
                                </TableCell>
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Potential increase in water storage capicity (in ltr)
                                </TableCell>
                                {/* <TableCell>
                                    <Box sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                        Population benifitted
                                    </Box>
                                    <Box>
                                        <TableCell style={{ padding: '5px' }}>GEN</TableCell>
                                        <TableCell style={{ padding: '10px' }}>ST</TableCell>
                                        <TableCell style={{ padding: '10px' }}>SC</TableCell>
                                    </Box>
                                </TableCell> */}
                                <TableCell sx={{ fontWeight: { xs: 200, sm: 700 }, fontSize: { xs: 12, sm: 14 } }}>
                                    Population benifitted
                                    <Table>
                                        <TableBody>
                                            <TableRow style={{ borderBottom: "none" }}>
                                                <TableCell style={{ padding: '5px' }}>GEN</TableCell>
                                                <TableCell style={{ padding: '10px' }}>ST</TableCell>
                                                <TableCell style={{ padding: '10px' }}>SC</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableCell>
                                {/* </TableCell> */}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {villagesInfoesWaterTask.map((allData, ind) => {
                                const taskFundedByName =
                                    taskFundedByNames.length > 0 &&
                                    taskFundedByNames.find((state) => state.value == allData.taskFundedBy);

                                return (
                                    <TableRow key={ind}>
                                        <TableCell align="left">
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                {allData.taskActivityName}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left">{data && data.villagename}</TableCell>
                                        <TableCell align="left">{data && data.grampanchayatname}</TableCell>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left">{allData.typeOfActivity}</TableCell>
                                        <TableCell align="left">{allData.taskActivityName}</TableCell>
                                        <TableCell align="left">{taskFundedByName && taskFundedByName?.name}</TableCell>
                                        {/* <TableCell align="left">{allData && allData.unitOfActivity}</TableCell> */}
                                        <TableCell align="left">
                                            {allData.totalUnitOfActivity} {allData.unitOfActivity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {allData.tentativeStartDate && formatedDate(allData.tentativeStartDate)}
                                        </TableCell>
                                        <TableCell align="left">
                                            {allData.estimatedCompletionTimeInMonths} month{' '}
                                            {allData.estimatedCompletionTimeInDays} days
                                        </TableCell>
                                        <TableCell align="left">{allData.perUnitEstimateCostOfActivity}</TableCell>
                                        <TableCell align="left">{allData.expectedWaterCapacity}</TableCell>
                                        {/* <TableCell align="left">
                                            <TableCell>{allData.generalBenefitted}</TableCell>
                                            <TableCell>{allData.stbenefitted}</TableCell>
                                            <TableCell>{allData.scbenefitted}</TableCell>
                                        </TableCell> */}
                                        <TableCell >
                                            <Table>
                                                <TableBody>
                                                    <TableRow style={{ borderBottom: "none" ,justifyContent:"space-between" }}>

                                                        <TableCell style={{width:"40px",textAlign:"center"}}>{allData.generalBenefitted}</TableCell>
                                                        <TableCell style={{width:"40px",textAlign:"center"}}>{allData.stbenefitted}</TableCell>
                                                        <TableCell style={{width:"40px",textAlign:"center"}}>{allData.scbenefitted}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>b: Proposed Water Potential will be created from the water improvement task</Typography>
                </Grid>
                <Grid container lineHeight={3} style={{ border: '1px solid gray' }}>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total Water Deficit/ Surplus in Village (Existing) (in lakh ltr.)
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {formatNumber(data?.status_of_deficit_surplus)}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Total proposed Water Conservation Potential (in lakh ltr)
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {Math.round(PotentialIncreaseWaterCapicity / 100000)}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '80%', paddingLeft: 2 }}>
                            Change in Water Deficit / Surplus (in lakh ltr) - Proposed
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '20%', paddingLeft: 2 }}>
                            {((data?.status_of_deficit_surplus + PotentialIncreaseWaterCapicity)).toFixed(2)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item="true" lineHeight={3} xs={12} sm={12} md={12} marginTop={5} marginBottom={1}>
                    <Typography>c: Information of the individual/group who generate the report </Typography>
                </Grid>
                <Grid container lineHeight={2} style={{ border: '1px solid gray' }}>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '40%', paddingLeft: 2 }}>
                            Name of person{' '}
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '60%', paddingLeft: 2 }}>
                            {userName && userName.firstName} {userName && userName.lastName}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '40%', paddingLeft: 2 }}>
                            Designation{' '}
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '60%', paddingLeft: 2 }}>
                            {panchayatDesignations && panchayatDesignations.designationName}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '40%', paddingLeft: 2 }}>
                            address1
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '60%', paddingLeft: 2 }}>
                            {userName && userName.address1}
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', width: '100%' }}>
                        <Grid item style={{ border: '1px solid gray', width: '40%', paddingLeft: 2 }}>
                            Contact Details{' '}
                        </Grid>
                        <Grid item style={{ border: '1px solid gray', width: '60%', paddingLeft: 2 }}></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default VillageSummaryRepo;

