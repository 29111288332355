import React, { useEffect, useState } from 'react'
import RightsComponent from './RightsComponent';
import basAxiosInstance from 'utils/baseApi';

export const exportToExcel = () => {
    const table = document.getElementById('dvExcelData');

    const cloneTable = table.cloneNode(true);

    const image = cloneTable.getElementsByTagName('img');
    Array.from(image).forEach((img) => {
        img.parentNode.removeChild(img);
    });

    const cells = cloneTable.getElementsByTagName('td');
    Array.from(cells).forEach((cell) => {
        cell.style.border = '1px solid #919191';
    });
    const rows = cloneTable.getElementsByTagName('tr');
    Array.from(rows).forEach((cell) => {
        cell.style.border = '1px solid #919191';
    });
    const colmns = cloneTable.getElementsByTagName('th');
    Array.from(colmns).forEach((cell) => {
        cell.style.border = '1px solid #919191';
    });

    const html = cloneTable.innerHTML;

    const blob = new Blob([html], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Summary_Report.xls');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

};

const ProfileData = () => {
    const [userId, setUserId] = useState();
    const [profileData, setProfileData] = useState();
    
    //Check User Rights function
    const rightsCo = RightsComponent() 
    useEffect(() => {
        const userid = rightsCo && rightsCo.UserId;
        setUserId(userid);
        }, [rightsCo]);


    // Get State data from server
    const fetchUserData = async (userId) => {
        try {
            const response = await basAxiosInstance.get(`/UserProfiles/${userId}`);

            if (response.status === 200) {
                setProfileData(response.data);
                sessionStorage.setItem('userProfile', JSON.stringify(userData));

            }
        } catch (err) {
            console.log('error', err);
        }
    };

    useEffect(() => {
        if(userId){
            fetchUserData(userId);
        }
    }, [userId]);

    return profileData;
}

export default ProfileData