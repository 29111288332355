import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import VillageSummaryRepo from 'views/utilities/Reports/VillageSummaryRepo';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// utilities routing

const UtilsDistrict = Loadable(lazy(() => import('views/utilities/District/District')));
const UtilsDistrictUpdate = Loadable(lazy(() => import('views/utilities/District/DistrictUpdate')));
const UtilsDistrictList = Loadable(lazy(() => import('views/utilities/District/DistrictList')));
const UtilsBlocks = Loadable(lazy(() => import('views/utilities/Blocks/Blocks')));
const UtilsBlocksUpdate = Loadable(lazy(() => import('views/utilities/Blocks/BlocksUpdate')));
const UtilsBlocksList = Loadable(lazy(() => import('views/utilities/Blocks/BlocksList')));
const UtilsEntities = Loadable(lazy(() => import('views/utilities/Entities/Entities')));
const UtilsEntitiesList = Loadable(lazy(() => import('views/utilities/Entities/EntitiesList')));
const UtilsEntitiesUpdate = Loadable(lazy(() => import('views/utilities/Entities/EntitiesUpdate')));
const UtilsRolesList = Loadable(lazy(() => import('views/utilities/Roles/RolesList')));
const UtilsPanchayatDesignation = Loadable(lazy(() => import('views/utilities/PanchayatDesignation/PanchayatDesignation')));
const UtilsPanchayatDesignationList = Loadable(lazy(() => import('views/utilities/PanchayatDesignation/PanchayatDesignationList')));
const UtilsPanchayatDesignationUpdate = Loadable(lazy(() => import('views/utilities/PanchayatDesignation/PanchayatDesignationUpdate')));
const UtilsCropsList = Loadable(lazy(() => import('views/utilities/Crops/CropsList')));
const UtilsUserRoles = Loadable(lazy(() => import('views/utilities/UserRole/UserRoles')));
const UtilsUserRolesList = Loadable(lazy(() => import('views/utilities/UserRole/UserRolesList')));
const UtilsUserRolesUpdate = Loadable(lazy(() => import('views/utilities/UserRole/UserRolesUpdate')));
const UtilsRolePermissionList = Loadable(lazy(() => import('views/utilities/RolePermission/RolePermissionList')));
const UtilsGramPanchayats = Loadable(lazy(() => import('views/utilities/GramPanchayats/GramPanchayats')));
const UtilsGramPanchayatsList = Loadable(lazy(() => import('views/utilities/GramPanchayats/GramPanchayatsList')));
const UtilsPermissionsUpdate = Loadable(lazy(() => import('views/utilities/Permissions/PermissionUpdate')));
const UtilsPermissions = Loadable(lazy(() => import('views/utilities/Permissions/Permission')));
const UtilsPermissionsList = Loadable(lazy(() => import('views/utilities/Permissions/PermissionList')));
const UtilsSoilTypesUpdate = Loadable(lazy(() => import('views/utilities/SoilTypes/SoilTypesUpdate')));
const UtilsSoilTypes = Loadable(lazy(() => import('views/utilities/SoilTypes/SoilTypes')));
const UtilsSoilTypesList = Loadable(lazy(() => import('views/utilities/SoilTypes/SoilTypesList')));
const UtilsWaterBodiesList = Loadable(lazy(() => import('views/utilities/WaterBodies/WaterBodiesList')));
const UtilsStatesUpdate = Loadable(lazy(() => import('views/utilities/States/StatesUpdate')));
const UtilsStates = Loadable(lazy(() => import('views/utilities/States/States')));
const UtilsStatesList = Loadable(lazy(() => import('views/utilities/States/StatesList')));
const UtilsWaterBodyTypesList = Loadable(lazy(() => import('views/utilities/WaterBodyTypes/WaterBodyTypesList')));
const UtilsVillagesUpdate = Loadable(lazy(() => import('views/utilities/Villages/VillagesUpdate')));
const UtilsVillages = Loadable(lazy(() => import('views/utilities/Villages/Villages')));
const UtilsVillagesList = Loadable(lazy(() => import('views/utilities/Villages/VillagesList')));
const UtilsVillagesInfoesList = Loadable(lazy(() => import('views/utilities/VillagesInfoes/VillagesInfoesList')));
const UtilsWaterBodyTypeAttributesUpdate = Loadable(lazy(() => import('views/utilities/WaterBodyTypeAttributes/WaterBodyTypeAttributesUpdate')));
const UtilsWaterBodyTypeAttributes = Loadable(lazy(() => import('views/utilities/WaterBodyTypeAttributes/WaterBodyTypeAttributes')));
const UtilsWaterBodyTypeAttributesList = Loadable(lazy(() => import('views/utilities/WaterBodyTypeAttributes/WaterBodyTypeAttributesList')));
const UtilsWaterBodyAttributeValueUpdate = Loadable(lazy(() => import('views/utilities/WaterBodyAttributeValue/WaterBodyAttributeValueUpdate')));
const UtilsWaterBodyAttributeValue = Loadable(lazy(() => import('views/utilities/WaterBodyAttributeValue/WaterBodyAttributeValue')));
const UtilsWaterBodyAttributeValueList = Loadable(lazy(() => import('views/utilities/WaterBodyAttributeValue/WaterBodyAttributeValueList')));
const UtilsAnimalTypesUpdate = Loadable(lazy(() => import('views/utilities/AnimalTypes/AnimalTypesUpdate')));
const UtilsAnimalTypes = Loadable(lazy(() => import('views/utilities/AnimalTypes/AnimalTypes')));
const UtilsAnimalTypesList = Loadable(lazy(() => import('views/utilities/AnimalTypes/AnimalTypesList')));
const UtilsAnimalList = Loadable(lazy(() => import('views/utilities/Animal/AnimalList')));
const UtilsUsersUpdate = Loadable(lazy(() => import('views/utilities/Users/UsersUpdate')));
const UtilsWaterTaskList = Loadable(lazy(() => import('views/utilities/WaterTask/WaterTaskList')));
const UtilsUsersList = Loadable(lazy(() => import('views/utilities/Users/UsersList')));
const UtilsCropTypesUpdate = Loadable(lazy(() => import('views/utilities/CropTypes/CropTypesUpdate')));
const UtilsCropTypes = Loadable(lazy(() => import('views/utilities/CropTypes/CropTypes')));
const UtilsCropTypesList = Loadable(lazy(() => import('views/utilities/CropTypes/CropTypesList')));
const UtilsGramPanchayatsUpdate = Loadable(lazy(() => import('views/utilities/GramPanchayats/GramPanchayatsUpdate')));
const UtilsUserProfile = Loadable(lazy(() => import('views/utilities/UserProfile/UserProfile')));
const UtilsUserProfileUpdate = Loadable(lazy(() => import('views/utilities/UserProfile/UserProfileUpdate')));
const UtilsUserProfileList = Loadable(lazy(() => import('views/utilities/UserProfile/UserProfileList')));
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const UtilsSummaryReport = Loadable(lazy(() => import('views/utilities/Reports/SummaryReport')));
const UtilsVillageSummaryRepo = Loadable(lazy(() => import('views/utilities/Reports/VillageSummaryRepo')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
     element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/utils/util-UserProfile',
            element: <UtilsUserProfile />
        },
        {
            path: '/utils/util-UserProfileList',
            element: <UtilsUserProfileList />
        },
        {
            path: '/utils/util-UserProfileUpdate',
            element: <UtilsUserProfileUpdate />
        },
        {
            path: '/utils/util-district',
            element: <UtilsDistrict />
        },
        {
            path: `/utils/util-districtUpdate`,
            element: <UtilsDistrictUpdate />
        },
        {
            path: '/utils/util-districtList',
            element: <UtilsDistrictList />
        },
        {
            path: '/utils/util-blocks',
            element: <UtilsBlocks />
        },
        {
            path: `/utils/util-blocksUpdate`,
            element: <UtilsBlocksUpdate />
        },
        {
            path: '/utils/util-blocksList',
            element: <UtilsBlocksList />
        },
        {
            path: '/utils/util-entities',
            element: <UtilsEntities />
        },
        {
            path: `/utils/util-EntitiesUpdate`,
            element: <UtilsEntitiesUpdate />
        },
        {
            path: '/utils/util-entitiesList',
            element: <UtilsEntitiesList />
        },
        {
            path: '/utils/util-RolesList',
            element: <UtilsRolesList />
        },
           {
            path: '/utils/util-AnimalTypes',
            element: <UtilsAnimalTypes />
        },
        {
            path: `/utils/util-AnimalTypesUpdate`,
            element: <UtilsAnimalTypesUpdate />
        },
        {
            path: '/utils/util-AnimalTypesList',
            element: <UtilsAnimalTypesList />
        },
        {
            path: '/utils/util-AnimalList',
            element: <UtilsAnimalList />
        },
        {
            path: '/utils/util-WaterBodyAttributeValue',
            element: <UtilsWaterBodyAttributeValue />
        },
        {
            path: `/utils/util-WaterBodyAttributeValueUpdate`,
            element: <UtilsWaterBodyAttributeValueUpdate />
        },
        {
            path: '/utils/util-WaterBodyAttributeValueList',
            element: <UtilsWaterBodyAttributeValueList />
        },
        {
            path: '/utils/util-VillagesInfoesList',
            element: <UtilsVillagesInfoesList />
        },
        {
            path: '/utils/util-Villages',
            element: <UtilsVillages />
        },
        {
            path: `/utils/util-VillagesUpdate`,
            element: <UtilsVillagesUpdate />
        },
        {
            path: '/utils/util-VillagesList',
            element: <UtilsVillagesList />
        },
        {
            path: '/utils/util-CropTypes',
            element: <UtilsCropTypes />
        },
        {
            path: `/utils/util-CropTypesUpdate`,
            element: <UtilsCropTypesUpdate />
        },
        {
            path: '/utils/util-CropTypesList',
            element: <UtilsCropTypesList />
        },
        {
            path: '/utils/util-WaterTaskList',
            element: <UtilsWaterTaskList />
        },
        {
            path: '/utils/util-UserRoles',
            element: <UtilsUserRoles />
        },
        {
            path: `/utils/util-UserRolesUpdate`,
            element: <UtilsUserRolesUpdate />
        },
        {
            path: '/utils/util-UserRolesList',
            element: <UtilsUserRolesList />
        },
        {
            path: '/utils/util-RolePermissionList',
            element: <UtilsRolePermissionList />
        },


        {
            path: '/utils/util-WaterBodyTypeAttributes',
            element: <UtilsWaterBodyTypeAttributes />
        },
        {
            path: `/utils/util-WaterBodyTypeAttributesUpdate`,
            element: <UtilsWaterBodyTypeAttributesUpdate />
        },
        {
            path: '/utils/util-WaterBodyTypeAttributesList',
            element: <UtilsWaterBodyTypeAttributesList />
        },
        {
            path: `/utils/util-UsersUpdate`,
            element: <UtilsUsersUpdate />
        },
        {
            path: '/utils/util-UsersList',
            element: <UtilsUsersList />
        },

        {
            path: '/utils/util-States',
            element: <UtilsStates />
        },
        {
            path: `/utils/util-StatesUpdate`,
            element: <UtilsStatesUpdate />
        },
        {
            path: '/utils/util-StatesList',
            element: <UtilsStatesList />
        },
        {
            path: '/utils/util-WaterBodyTypesList',
            element: <UtilsWaterBodyTypesList />
        },
        {
            path: '/utils/util-WaterBodiesList',
            element: <UtilsWaterBodiesList />
        },
        {
            path: '/utils/util-SoilTypes',
            element: <UtilsSoilTypes />
        },
        {
            path: `/utils/util-SoilTypesUpdate`,
            element: <UtilsSoilTypesUpdate />
        },
        {
            path: '/utils/util-SoilTypesList',
            element: <UtilsSoilTypesList />
        },      
        {
            path: '/utils/util-Permissions',
            element: <UtilsPermissions />
        },
        {
            path: `/utils/util-PermissionsUpdate`,
            element: <UtilsPermissionsUpdate />
        },
        {
            path: '/utils/util-PermissionsList',
            element: <UtilsPermissionsList />
        },
        {
            path: '/utils/util-PanchayatDesignation',
            element: <UtilsPanchayatDesignation />
        },
        {
            path: `/utils/util-PanchayatDesignationUpdate`,
            element: <UtilsPanchayatDesignationUpdate />
        },
        {
            path: '/utils/util-PanchayatDesignationList',
            element: <UtilsPanchayatDesignationList />
        },
        {
            path: '/utils/util-CropsList',
            element: <UtilsCropsList />
        },

        {
            path: '/utils/util-GramPanchayats',
            element: <UtilsGramPanchayats />
        },
        {
            path: `/utils/util-GramPanchayatsUpdate`,
            element: <UtilsGramPanchayatsUpdate />
        },
        {
            path: '/utils/util-GramPanchayatsList',
            element: <UtilsGramPanchayatsList />
        },
        {
            path: '/utils/util-SummaryReport',
            element: <UtilsSummaryReport />
        },
        {
            path: '/utils/village/util-SummaryReport',
            element: <UtilsVillageSummaryRepo />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
