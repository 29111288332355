import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import rightsReducer from 'store/rightsReducer';

const RightsComponent = () => {
    // const state = useSelector((state)=>state.Rights)
    // const dispatch = useDispatch()
    const [newToken, setNewToken] = useState('');
    const [rights, setrights] = useState();
    const chekRights = () => {
        if (newToken) {
            const user = jwtDecode(newToken);
            // dispatch(rightsReducer(rights))
            // const CropVal = user;
            setrights(user);
        } else {
        }
    };
    useEffect(() => {
        setNewToken(sessionStorage.getItem('authToken'));
        chekRights();
    }, [newToken]);

    return rights;
};

export default RightsComponent;
