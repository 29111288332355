import { ADD_BLOCK, UPDATE_BLOCK } from './actions';

const initialState = {
    blockList: []
};

const blockReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_BLOCK:
            return {
                ...state,
                blockList: action.payload
            };

        case UPDATE_BLOCK:
            return {
                ...state,
                blockList: state.blockList.map((block) => {
                    return block.blockId === action.payload.Id ? { ...block, ...action.payload.updateData } : block;
                }),
            };

        //     case DELETE_DISTRICT:
        //         return {
        //             ...state,
        //             districtList: state.districtList.filter((district) =>
        //                 district.id !== action.payload
        //             ),
        //         };
        default:
            return state;
    }
};

export default blockReducer;
