import { ADD_GRAMPANCHAYAT, UPDATE_GRAMPANCHAYAT } from './actions';

const initialState = {
    gramPanchayatList: []
};

const gramPanchayatReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_GRAMPANCHAYAT:
            return {
                ...state,
                gramPanchayatList: action.payload
            };

            case UPDATE_GRAMPANCHAYAT:
                return {
                    ...state,
                    gramPanchayatList: state.gramPanchayatList.map((gp) => {
                        return gp.gramPanchayatId === action.payload.Id ? { ...gp, ...action.payload.updateData } : gp;
                    }),
                };
        // case UPDATE_DISTRICT:
        //     return {
        //         ...state,
        //         districtList: state.districtList.map((district) =>
        //             district.id === action.payload.districtId ? { ...district, ...action.payload.updateData } : district
        //         ),
        //         districtList: [...state.districtList, action.payload]
        //     };

        //     case DELETE_DISTRICT:
        //         return {
        //             ...state,
        //             districtList: state.districtList.filter((district) =>
        //                 district.id !== action.payload
        //             ),
        //         };
        default:
            return state;
    }
};

export default gramPanchayatReducer;
