import { ADD_VILLAGE, UPDATE_VILLAGE } from './actions';

const initialState = {
    villageList: []
};

const villageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_VILLAGE:
            return {
                ...state,
                villageList: action.payload
            };

            case UPDATE_VILLAGE:
                return {
                    ...state,
                    villageList: state.villageList.map((village) => {
                        return village.villageId === action.payload.Id ? { ...village, ...action.payload.updateData } : village;
                    }),
                };

        default:
            return state;
    }
};

export default villageReducer;
