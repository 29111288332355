// project imports
import { USER_ROLE, USER_ROLE_UPDATE } from './actions';

// action - state management

export const initialState = {
    roleDate: []
};

// ==============================|| LOGIN REDUCER ||============================== //

const userRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_ROLE_UPDATE:
            return {
                ...state,
                roleDate: action.payload
            };
        default:
            return state;
    }
};

export default userRoleReducer;
