import { ADD_ANIMAL_TYPE } from './actions';

const initialState = {
    animalTypeList: []
};

const animalTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ANIMAL_TYPE:
            return {
                ...state,
                animalTypeList: action.payload
            };

        default:
            return state;
    }
};

export default animalTypeReducer;
