import { ADD_DISTRICT, DELETE_DISTRICT, UPDATE_DISTRICT } from './actions';

const initialState = {
    districtList: []
};

const districtReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DISTRICT:
            return {
                ...state,
                districtList: action.payload
            };

            case UPDATE_DISTRICT:
                return {
                    ...state,
                    districtList:state.districtList.map((district) => {
                        return district.districtId === action.payload.Id ? { ...district, ...action.payload.updateData } : district;
                    }),
                };
        default:
            return state;
    }
};

export default districtReducer;
