// project imports
import { LOGIN_SUCCESS, LOGOUT } from './actions';

// action - state management

export const initialState = {
    isLoggedIn: false
};

// ==============================|| LOGIN REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true
            };

        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false
            };

        default:
            return state;
    }
};

export default authReducer;
