import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './authReducer';
import userRoleReducer from './userRoleReducer';
import districtReducer from './districtReducer';
import stateReducer from './stateReducer';
import villageReducer from './villageReducer';
import animalTypeReducer from './animalTypeReducer';
import blockReducer from './blockReducer';
import gramPanchayatReducer from './gramPanchayatReducer';
import rightsReducer from './rightsReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    authReducer: authReducer,
    userRoleReducer: userRoleReducer,
    gramPanchayatReducer: gramPanchayatReducer,
    blockReducer: blockReducer,
    districtReducer: districtReducer,
    stateReducer: stateReducer,
    villageReducer: villageReducer,
    animalTypeReducer: animalTypeReducer,
    rightsReducer: rightsReducer
});

export default reducer;
