// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const USER_ROLE_UPDATE = 'USER_ROLE_UPDATE';
export const ADD_STATE = 'ADD_STATE';
export const UPDATE_STATE = 'UPDATE_STATE';
export const ADD_DISTRICT = 'ADD_DISTRICT';
export const UPDATE_DISTRICT = 'UPDATE_DISTRICT';
export const ADD_BLOCK = 'ADD_BLOCK';
export const UPDATE_BLOCK = 'UPDATE_BLOCK';
export const RIGHTS = 'RIGHTS';
export const ADD_GRAMPANCHAYAT = 'ADD_GRAMPANCHAYAT';
export const UPDATE_GRAMPANCHAYAT = 'UPDATE_GRAMPANCHAYAT';


export const ADD_VILLAGE = 'ADD_VILLAGE';
export const UPDATE_VILLAGE = 'UPDATE_VILLAGE';

export const ADD_ANIMAL_TYPE = 'ADD_ANIMAL_TYPE';

// export const DELETE_DISTRICT = 'DELETE_DISTRICT';

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS
});
export const logout = () => ({
    type: LOGOUT
});

export const USER_ROLE = 'USER_ROLE';
export const UserRole = () => ({
    type: USER_ROLE
});
export const rights = (rights) => ({
    type: RIGHTS,
    payload: {
        rights
    }
});

export const UserRoleUpdate = (roleData) => ({
    type: USER_ROLE_UPDATE,
    payload: {
        roleData
    }
});


export const addState = (state) => ({
    type: ADD_STATE,
    payload: state
});
export const updateState = (Id, updateData)=>({
    type: UPDATE_STATE,
    payload:{Id, updateData},
})

export const addDistrict = (district) => ({
    type: ADD_DISTRICT,
    payload: district
});
export const updateDistrict = (Id, updateData)=>({
    type: UPDATE_DISTRICT,
    payload:{Id, updateData},
})

// export const deleteDistrict = (districtId)=>({
//     type: DELETE_DISTRICT,
//     payload:districtId,
// })

export const addBlock = (block) => ({
    type: ADD_BLOCK,
    payload: block
});
export const updateBlock = (Id, updateData)=>({
    type: UPDATE_BLOCK,
    payload:{Id, updateData},
})
export const addGramPanchayat = (grampanchayat) => ({
    type: ADD_GRAMPANCHAYAT,
    payload: grampanchayat
});
export const updateGramPanchayat = (Id, updateData)=>({
    type: UPDATE_GRAMPANCHAYAT,
    payload:{Id, updateData},
})
export const addVillage = (village) => ({
    type: ADD_VILLAGE,
    payload: village
});
export const updateVillage = (Id, updateData)=>({
    type: UPDATE_VILLAGE,
    payload:{Id, updateData},
})

export const addanimalType = (animalType) => ({
    type: ADD_ANIMAL_TYPE,
    payload: animalType
});
